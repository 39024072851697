.warn_btns > *:not(:last-child) {
  margin-right: 6px;
}

.warnContainer {
  display: flex;
  flex-direction: column;

  border-radius: 10px;

  font-family: calibri;
}
