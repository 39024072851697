.MuiButton-contained {
  padding: 0px !important;
}

.checkbox {
  height: 16px;
  width: 16px;
  margin-top: 2px;
  border-radius: 50%;
}
