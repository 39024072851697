.dialog_scroll_area::-webkit-scrollbar {
  width: 10px;
}
.dialog_scroll_area::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.dialog_scroll_area::-webkit-scrollbar-thumb {
  background: #888;
}
.dialog_scroll_area::-webkit-scrollbar-thumb:hover {
  background: #555;
}
