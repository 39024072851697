.fields {
  display: flex;
  padding: 8px;
  flex-direction: column;
}

button {
  cursor: pointer;
}

.custom_style {
  margin-right: 10px;
}
