.main_intro {
  width: 100%;
  height: 100%;

  flex-direction: column;
  display: flex;
}

.intro {
  display: flex;

  width: 100%;
  height: 100%;

  flex-direction: column;
}

.content {
  display: flex;

  overflow: auto;

  max-height: 100%;
  font-family: Calibri;
  flex-direction: column;
}

.header {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 20px;
}

.get_started,
.add_lists {
  margin-top: 20px;
}

.sign_out {
  margin-top: 20px;
}
