.sidebar {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.sidebar_btns {
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.user_div {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.sidebar_btns::-webkit-scrollbar {
  width: 10px;
}
.sidebar_btns::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.sidebar_btns::-webkit-scrollbar-thumb {
  background: #888;
}
.sidebar_btns::-webkit-scrollbar-thumb:hover {
  background: #555;
}
