.list_drag {
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
}



.list_drag::-webkit-scrollbar {
  width: 10px;
}
.list_drag::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.list_drag::-webkit-scrollbar-thumb {
  background: #888;
}
.list_drag::-webkit-scrollbar-thumb:hover {
  background: #555;
}
