.nav {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.nav_data {
  display: flex;
  height: 100%;
  flex-direction: row;
  overflow: hidden;
}

.user {
  display: flex;
}

.plat {
  margin-left: 6px;
}
