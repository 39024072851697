input[type="radio"] {
  cursor: pointer;
  margin-right: 10px;
}

.radio_div {
  display: flex;
  font-size: 20px;
  cursor: pointer;
  font-family: "Calibri";
  margin-bottom: 4px;
  padding: 8px;
}

.radio_div:hover {
  color: white;
  background-color: navy;
}

.icon_div {
  margin-right: 8px;
}
