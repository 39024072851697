.cell {
  white-space: pre-wrap; /* Preserve line breaks */
  word-wrap: break-word; /* Allow long words to break */
  overflow-wrap: break-word; /* Allow word breaking for long words */
  flex-shrink: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 200px;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 2px solid red;
}

.italy {
  font-style: italic;
}

.cell_first_row {
  white-space: pre-wrap; /* Preserve line breaks */
  word-wrap: break-word; /* Allow long words to break */
  overflow-wrap: break-word; /* Allow word breaking for long words */
  flex-shrink: 0;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 200px;
  font-weight: bold;
  font-size: 20px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: orange;
}

.bright_row {
  margin-top: 10px;
  display: flex;
  overflow-x: visible;

  color: black;
}

.dark_row {
  margin-top: 10px;

  overflow-x: visible;

  display: flex;
  color: black;
}

.data_row:hover {
  background-color: azure;
}

.data_row_dark:hover {
  background-color: red;
}
