.project {
  font-size: 20px;
  user-select: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
}

.project_icon {
  margin-right: 10px;
  width: 30px;
  display: flex;
  align-items: center;
}

.project_name_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.project_name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.project_count {
  display: flex;
  justify-content: center;
  align-items: center;
}
