html,
body,
.root,
.root > div {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Calibri;
}
#root {
  height: 100%;
}
