.editContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  font-family: calibri;
}

@media (min-width: 800px) {
  .editContainer {
    align-items: center; /* Centers items horizontally */
    justify-content: center; /* Centers items vertically */
  }

  .itemsContainer {
    width: 450px;
  }
}
.btns {
  display: flex;
  margin-top: 6px;
  flex-direction: row;
  justify-content: flex-end;
}

.itemsContainer {
  display: flex;
  flex-direction: column;
}

.btns > *:not(:last-child) {
  margin-right: 6px;
}
