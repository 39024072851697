.details {
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: center;
  font-family: Calibri;
  font-size: 24px;
}

.details_box {
  padding: 6px;
  border-radius: 10px;
}
