.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  right: 0;
  z-index: 1;
}

.overylayContainer {
  width: 100%;

  display: flex;
}
