.task {
  font-size: 20px;
  user-select: none;
  border: 1px solid lightgray;
  word-break: break-word;
  margin-top: 6px;
  border-radius: 6px;
  display: flex;
}

.task_description {
  margin-top: 6px;
  font-size: 16px;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* replace 3 with the number of lines you want to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.sub {
  font-size: 16px;
  margin-right: 10px;
}

.subs {
  margin-top: 6px;
  display: flex;
  flex-direction: row;
}

.due_date_icon {
  margin-right: 4px;
}

.duedate {
  display: flex;
  flex-direction: row;
}

.name_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.task_flag {
  display: flex;
}
