.normal {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  margin-bottom: 2px;
  border-left: 1px solid black;
  border-right: 1px solid black; /* Default bottom border color, e.g., black */
}

.no_borders {
  background-color: white;
  border-bottom: 3px solid gray;
  border-top: 0px solid black;
  border-radius: 0px;
  margin-bottom: 2px;
  border-left: 0px solid black;
  border-right: 0px solid black; /* Default bottom border color, e.g., black */
}

textarea::placeholder {
  white-space: nowrap;
  overflow: hidden; /* Optional: Hide any text that overflows */
}

.no_borders_with_background {
  background-color: lightgray;
  border-bottom: 0px solid black;
  border-top: 0px solid black;
  border-radius: 6px;
  margin-bottom: 2px;
  border-left: 0px solid black;
  border-right: 0px solid black; /* Default bottom border color, e.g., black */
}

.no_borders_dark_with_background {
  background-color: white;
  border-bottom: 0px solid black;
  border-top: 0px solid black;
  border-radius: 6px;
  margin-bottom: 2px;
  border-left: 0px solid black;
  border-right: 0px solid black; /* Default bottom border color, e.g., black */
}

.no_borders_dark {
  background-color: #343541;
  color: white;
  margin-bottom: 2px;
  border-radius: 0px;
  border-left: 0px solid black;
  border-top: 0px solid black;
  border-right: 0px solid black;
  border-bottom: 3px solid gray; /* Default bottom border color, e.g., black */
}

.normal:focus {
  margin-bottom: 0px;
  border-bottom: 3px solid blue;
}

.no_borders:focus {
  background-color: white;

  margin-bottom: 2px;
  border-radius: 0px;
  border-bottom: 3px solid blue;
}

.no_borders_dark_with_background:focus {
  background-color: #343541;
  color: white;
  margin-bottom: 0px;
  border-radius: 0px;
  border-bottom: 3px solid pink;
}

.no_borders_with_background:focus {
  background-color: white;

  margin-bottom: 0px;
  border-radius: 0px;
  border-bottom: 3px solid blue;
}

.no_borders_dark:focus {
  background-color: #343541;
  color: white;
  margin-bottom: 2px;
  border-radius: 0px;
  border-bottom: 3px solid pink;
}
