@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.rowAnimation {
  animation: fadeInUp 0.5s ease-out forwards;
}
